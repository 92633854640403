/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    hr: "hr",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "IBM Quantum is IBM's quantum computing initiative, encompassing research and development of quantum hardware and quantum software (Qiskit), collaborations with academic institutions, and industry partnerships."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "IBM Quantum has a few notable public-facing branches that stem from it:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Qiskit: Qiskit is an open-source quantum software development kit (SDK) built in Python. With its first stable release in February 2024, it allows users to simulate quantum circuits and apply them to adjacent fields like quantum machine learning and quantum chemistry."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "IBM Quantum Systems: This contains IBM's quantum computers that are available to use and program remotely via Qiskit. Some resources are available on a restricted free tier, and some are available through staggered pricing plans."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "IBM Quantum Platform: This is the online hub where users can see their work being churned out by available quantum computers."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Learn more about ", React.createElement(_components.a, {
    href: "https://www.ibm.com/quantum"
  }, "IBM Quantum on the IBM website"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
